html {
  width: 100%;
  text-size-adjust: none;
  min-height: -webkit-fill-available;
  min-height: 100%;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  color: #191D32;
  letter-spacing: 0.5px;
  * {
    box-sizing: border-box;

  }
}

.app {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 56px;
}

.app_container {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.header_wrap {
  z-index: 99;
  position: fixed;
  width: 100%;
  top: 0;
}

.header {
  height: 56px;
  width: 100%;
  max-width: 560px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);

}

.dropdown {
  position: relative;
  z-index: 20;
  img {
    margin-right: 8px;
    display: inline-block;
    width: 32px;
    height: 24px;
  }
  .dropdown-title {
    text-transform: uppercase;
  }
  ul {
    width: 100%;
    display: flex;
    position: absolute;
    flex-direction: column;
    list-style: none;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 0 16px;
    right: 0;
    visibility: hidden;
    transition: .1s;
    li {
      width: 100%;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      padding: 12px 0;
      position: relative;
      cursor: pointer;
      &:hover {
        color: #597DF8;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      &:after {
        content: '';
        display: inline-block;
        height: 1px;
        width: calc(100% - 8px);
        position: absolute;
        left: 4px;
        background-color: #F1F3F4;
        bottom: 0;
      }
      img {
        margin-right: 8px;
      }
    }
  }
  div {
    display: flex;
    align-items: center;
    cursor: pointer;
    > span {
      height: 24px;
    }
    > img {
      &:last-child {
        margin-right: 0;
        width: 18px;
        height: 8px;
      }
    }
  }
  &.active {
    ul {
      visibility: visible;
    }
  }
  > span {
    img:last-child {
      transform: scale(-1);
    }
  }
  &-item {
    img {

    }
    &.active {
      img {

      }
    }
  }
}

.logo {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-decoration: none;
  font-family: 'Red Hat Display', sans-serif;
  display: flex;
  align-items: center;
  font-style: normal;
}

.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px;
  max-width: 568px;
  margin: 0 auto;
  h1 {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 16px;
    text-align: center;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
}

.form {
  margin: 0 auto;
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  max-width: 560px;
  h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding:  0 0 16px;
  }
  label:not(.upload-files) {
    width: 100%;
    position: relative;
    margin-bottom: 32px;
    &.inputError {
      .errorText {
        display: inline-block;
      }
      input {
        border-color: #E63946;
      }
      span, .placeholderText {
        color: #E63946 !important;
      }
    }
    .placeholderText {
      position: absolute;
      left: 16px;
      top: 8px;
      color: #ABAEB8;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
    .errorText {
      color: #F62D51;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      display: none;
      position: absolute;
      left: 16px;
      bottom: -16px;
    }
    input {
      width: 100%;
      border: 1px solid #D6D6D6;
      border-radius: 4px;
      min-height: 56px;
      padding: 22px 16px 8px;
      outline: none;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      &::placeholder {
        color: #ABAEB8;
      }
      &:focus {
        border-color: #597DF8;
        + p {
          color: #597DF8;
        }
      }
    }
    span:not(.errorText) {
      position: absolute;
      right: 16px;
      top: 20px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #ABAEB8;
    }
  }
  .field-wrapper {
    display: flex;
    .dropdown-wrap {
      min-width: 140px;
      display: flex;
      align-items: center;
      //padding: 0 8px;
      margin-left: 16px;
      border: 1px solid #D6D6D6;
      border-radius: 4px;
      height: 56px;
      .dropdown {
        width: 100%;
        height: 100%;
        display: flex;
        > div {
          padding: 0 16px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }
  }
  .comment_wrap {
    position: relative;
    display: flex;

    .comment_required_label {
      position: absolute;
      right: 16px;
      top: 12px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #ABAEB8;
    }
  }


  textarea {
    width: 100%;
    border: 1px solid #D6D6D6;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px 16px;
    outline: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    resize: none;
    height: 128px;
    &::placeholder {
      color: #ABAEB8;
    }
    &.inputError {
      border-color: #E63946;
      & + .comment_required_label {
        color: #E63946;;
      }
    }
  }
  button {
    background-color: #597DF8;
    border-radius: 8px;
    padding: 16px;
    color: #ffffff;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
  }
}

.advantages {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 24px 0 68px;
  max-width: 560px;
  @media (min-width: 960px) {
    padding: 96px 0 120px;
  }
  img {
    margin-bottom: 24px;
  }
  .advantages_item + .advantages_item {
    margin-top: 48px;
  }
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px;
    h3 {
      text-align: center;
      margin-bottom: 16px;
      font-size: 22px;
      line-height: 28px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
    }
    p {
      text-align: center;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
  }
}

.modal {
  &_wrap {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgb(0 0 0 / 60%);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &_content {
    width: 280px;
    background-color: #fff;
    padding: 16px;
    padding-top: 24px;
    padding-bottom: 0;
    border-radius: 8px;
    h3 {
      text-align: center;
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
    }
    p {
      text-align: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 16px;
    }

    button {
      background-color: transparent;
      border: none;
      border-top: 1px solid #E3E5E6;
      margin: 0 -12px;
      width: calc(100% + 24px);
      padding: 12px;
      font-size: 16px;
      color: #597DF8;
      cursor: pointer;
    }
  }
}


.field_cv {
  margin: 32px 0 24px;
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  padding: 16px;
  background: #E4EAFE;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  &.disabled {
    pointer-events: none;
  }
  &.inputError {
    border-color: #E63946;
    * {
      color: #E63946 !important;
    }
  }
}

.field_cv .title {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.field_cv .subtitle {
  margin-top: 4px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #262C4D;
}

.field_cv .dropped_file_message {
  font-style: normal;
  display: none;
  width: 100%;
  z-index: 1;
  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    flex-grow: 2;
    text-align: center;
  }
}

.field_cv.dropped {
  overflow: hidden;
  border: none;
}

.field_cv.dropped .title,
.field_cv.dropped .subtitle {
  display: none;
}

.field_cv.dropped .dropped_file_message {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#drop-region {
  position: relative;
  min-height: 64px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
#drop_image {
  position: absolute;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
}
#remove_dropped_file {
  display: none;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 4px;
  &.active {
    display: flex;
  }
}

#progress_bar {
  position: absolute;
  left: 0;
  height: 100%;
  width: 0;
  -webkit-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
  background: #D5F2F0;
  &.empty {
    width: 0 !important;
  }
}

.ReCapchaWrapp {
  display: flex;
  margin: auto;
  margin-bottom: 24px;
  border-radius: 4px;
  &__error {
    border: 1px solid #F62D51;
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.footer {
  background-color: #262C4D;
  color: #FFFFFF;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  &-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
  }
  &-address {
    max-width: 260px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;
  }
  a {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-decoration: none;
    color: #597DF8;
    margin-bottom: 8px;
  }
  a:visited,
  a:active, {
    color: #597DF8;
  }
  &-email {
    color: #597DF8;
  }
  &-cards {
    margin: 26px auto 8px;
    img {
      width: 100%;
      max-width: 292px;
    }
  }
}

.footer_provide {
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
